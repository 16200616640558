@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,700|Roboto:400,700");

@import "variables"

*
	box-sizing border-box

html, body
	width: 100%
	height: 100%
	margin: 0
	padding: 0
	font-family: "Roboto"

body
	display: block
	background-image: url("/img/bg2.jpg")
	background-repeat: no-repeat
	background-position: center center
	background-color: black
	color: white
	padding-top: 110px
	@media (max-width: $container-size)
		padding-top: 140px

:focus
	outline: 0

header
	margin: 0px auto

	img
		height: 128px
		display: block
		margin: 0px auto

.container
	max-width: $container-size
	margin: 0px auto

main.container
	padding: 30px

#map
	height 300px
	// margin-bottom: 50px
	img
		-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
		filter: grayscale(100%);

nav
	background: black
	box-shadow: 0 0 16px 4px black
	position: fixed
	left: 0
	top: 0
	right: 0
	z-index: 99999
	overflow hidden

	.container
		display: flex
		justify-content: center
		align-items: center
		padding: 10px
		padding-top: 0
	
	img
		height: 100px
		margin-right: 20px

	.nav-items
		flex: 1
		display: flex
		justify-content: flex-end
		font-family: "Roboto Condensed"
		letter-spacing: 0.1ch

		.item
			font-weight: bold
			font-size: 14px
			text-transform: uppercase
			color: darken(white, 20%)
			display: flex
			align-items: center

			&:after
				margin: 0 8px
				display: inline-block
				content: "/"

			&:last-child:after
				display: none

			span
				display: block
				cursor: pointer
				padding: 4px
				&:hover
					color: white

			&.current span
				color: $color

	@media screen and (max-width: $container-size)
		img
			height: 64px
		.container
			flex-direction: column
		.nav-items
			// flex-direction: column;
			flex-wrap: wrap
			justify-content: center
			.item span
				padding: 8px 16px

section
	// min-height: 100%;
	// border-left: 2px solid $border-color;
	// border-right:2px solid $border-color;
	padding-bottom: 20px
	img.logo
		display: block
		width: 256px
		margin: 0px auto
	img.map
		width: 100%
		padding: 10px
	span.primary
		color $color
	h1, h2
		font-family: "Roboto Condensed"
		font-size: 48px
		text-transform: uppercase
		font-weight: bold
		margin: 0
		margin-bottom: 16px
		span
			display: inline-block
			padding-bottom: 2px
			border-bottom: 4px solid white
	h1
		text-align: center
	p
		line-height: 1.65
		margin: 0
		padding-bottom: 20px
	a
		color: $color
		font-weight: bold
	ul
		padding-left: 18px
		li
			line-height: 1.65
			padding-bottom: 10px
	iframe
		max-width 100%
.impressions
	display: flex
	align-items: flex-start
	flex-wrap: wrap
	.row
		width: 33%
	.picture
		padding: 5px
		img
			width: 100%
			padding-right: 5px
	@media (max-width: $container-size)
		.row
			width: 50%
			min-width: 50%
			img
				max-height: 200px
				max-width: 100%
				width: auto
				margin: 0 auto
				display: block